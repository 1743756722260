import * as React from 'react';
import {
    SelectField,
    NumberField,
    Button,
    Spinner,
    FieldWrapper,
    CheckboxField
} from '@imposium-hub/components';
import { fields as copy, layerOptions } from '../constants/copy';
import { COMPOSITION_LAYER_TYPES, SOURCE_TYPES, TEMPLATE_TYPES } from '../constants/story';
import { getFirstAct, getVariableOptions, getVariableVersions } from '../util/story';
import {
    IStory,
    IVariables,
    NEW_ASSET_SOURCE,
    NEW_ASSET_TAGS_SOURCE,
    NEW_INVENTORY_SOURCE,
    NEW_TTS_CONFIG,
    NEW_TEXT_SOURCE
} from '../constants/snippets';
import TagFields from './TagFields';
import { logError } from '../util/notifications';
import { api } from '../constants/app';
import CustomVarFields from './CustomVarFields';
import { downloadTemplateArchive, ITemplateArchiveBody } from '../util/template-generator';
import { connect } from 'react-redux';
import { IProject } from '../redux/reducers/project';
import { IEditor } from '../redux/reducers/editor';
import { addViewer } from '../redux/actions/story';
import { bindActionCreators } from 'redux';
import { createNewHTMLAsset, updateEditorConfig } from '../redux/actions/editor';
import { TextToSpeechOptions } from './TextToSpeechOptions';
import { ICON_QUESTION, ICON_WARNING } from '../constants/icons';
import ReactTextareaAutocomplete from '@imposium-hub/react-textarea-autocomplete';
import { reactAutoCompleteSettings } from '../util/fields';
import { setLayerErrors } from '../redux/actions/layerErrors';
import { checkAvailableAssets } from './filterListTable';
import AssetField from './assets/AssetField';
import packageJson from '../../package.json';
import { saveAs } from 'file-saver';

interface IMediaSourceProps {
    story: IStory;
    project: IProject;
    variables: IVariables;
    editor: IEditor;
    compositions: any;
    storyId: string;
    swapDuration: boolean;
    source: any;
    layerType: string;
    typeOptions: any[];
    duration: number;
    onChange(options: any): void;
    createNewHTMLAsset(): any;
    addViewer(config: any): void;
    setLayerErrors(id, e): void;
    layerErrors: any;
    updateEditorConfig(c): void;
    activeTimelineLayer: string;
    layerSources: any;
    layerId: string;
}

interface IMediaSourceState {
    openFilteredList: boolean;
}

class MediaSource extends React.PureComponent<IMediaSourceProps, IMediaSourceState> {
    private evtHandlers = {
        sourceType: (f) => this.sourceTypeChanged(f),
        invId: (v) => this.updateSouceField({ inventory_id: v }),
        invVersion: (v) => this.updateSouceField({ version: v }),
        assetId: (v) => this.updateSouceField({ asset_id: v ? v.id : null }),
        assetTags: (v) => this.updateSouceField({ asset_tags: v }),
        assetVars: (v) => this.updateSouceField({ asset_vars: v }),
        color: (v) => this.updateSouceField({ color: v.hex }),
        tts: (v) => this.toggleTTS(v),
        ttsConfig: (v) => this.updateSouceField({ text_to_speech: v }),
        videoOffset: (v) => this.updateSouceField({ offset: v }),
        assetError: (m) => {
            logError(m);
        },
        optional: (v) => this.updateSouceField({ optional: v }),
        ttsText: (v) => this.updateSouceField({ text: v })
    };

    constructor(props) {
        super(props);

        this.state = {
            openFilteredList: false
        };
    }

    public componentDidMount() {
        this.checkAvailableAssetsHandler();
    }

    public componentDidUpdate(prevProps) {
        if (
            prevProps.source !== this.props.source ||
            prevProps.duration !== this.props.duration ||
            prevProps.layerSources !== this.props.layerSources
        ) {
            this.checkAvailableAssetsHandler();
        }
    }

    private checkAvailableAssetsHandler() {
        const {
            storyId,
            layerType,
            duration,
            source: { from, asset_tags },
            activeTimelineLayer,
            variables
        } = this.props;

        if (from === SOURCE_TYPES.ASSET_TAGS) {
            checkAvailableAssets(
                asset_tags,
                layerType,
                storyId,
                duration,
                activeTimelineLayer,
                variables
            );
        }
    }

    private toggleTTS(v) {
        if (v) {
            const merged = { ...this.props.source, ...{ text_to_speech: NEW_TTS_CONFIG } };
            this.props.onChange(merged);
        } else {
            const merged = { ...this.props.source };
            delete merged.text_to_speech;
            this.props.onChange(merged);
        }
    }

    private sourceTypeChanged(type) {
        const { layerType } = this.props;
        let src;

        switch (type) {
            // variable
            case SOURCE_TYPES.INVENTORY:
                src = { ...NEW_INVENTORY_SOURCE };
                src['asset_type'] = layerType;
                break;

            // asset
            case SOURCE_TYPES.ASSET:
                src = { ...NEW_ASSET_SOURCE };
                src['asset_type'] = layerType;
                break;

            // asset from tags
            case SOURCE_TYPES.ASSET_TAGS:
                src = { ...NEW_ASSET_TAGS_SOURCE };
                src['asset_type'] = layerType;
                break;

            // text
            case SOURCE_TYPES.TEXT:
                src = { ...NEW_TEXT_SOURCE };
                break;
        }

        this.props.onChange(src);
    }

    private updateSouceField(source) {
        const merged = { ...this.props.source, ...source };
        this.props.onChange(merged);
    }

    private renderAssetsAvailable() {
        const {
            swapDuration,
            activeTimelineLayer,
            source: { from, asset_tags },
            layerErrors
        } = this.props;

        if (from === SOURCE_TYPES.ASSET_TAGS && asset_tags.length > 0) {
            const filteredList = layerErrors[activeTimelineLayer]?.filteredList;
            const filteredAssetsLength = layerErrors[activeTimelineLayer]?.filteredAssetsLength;
            const warning =
                filteredList && filteredList.length > 0 && !swapDuration ? ICON_WARNING : '';
            const message = `Asset Pool: ${filteredAssetsLength}`;

            return (
                <div
                    className={`asset-count ${warning ? 'warning' : ''}`}
                    onClick={() => (warning ? this.openFilteredListHandler() : null)}>
                    {message} {warning}
                </div>
            );
        } else {
            return null;
        }
    }

    private openFilteredListHandler() {
        const {
            editor: { openFilteredList }
        } = this.props;

        this.props.updateEditorConfig({
            openFilteredList: !openFilteredList
        });
    }

    private renderSourceInputs() {
        const {
            source: {
                from,
                asset_type,
                inventory_id,
                version,
                offset,
                asset_tags,
                text_to_speech,
                asset_id,
                asset_vars,
                text
            },
            layerType,
            variables,
            storyId,
            editor: { showCopyPropIds },
            project: { compositionId },
            layerId
        } = this.props;
        let inputs = [];

        const hasTTS = text_to_speech !== undefined;

        const customVarFields =
            layerType === COMPOSITION_LAYER_TYPES.TEMPLATE ||
            layerType === COMPOSITION_LAYER_TYPES.HTML ? (
                <CustomVarFields
                    key='var-fields'
                    vars={asset_vars}
                    type={layerType}
                    onChange={this.evtHandlers.assetVars}
                />
            ) : null;

        switch (from) {
            case SOURCE_TYPES.TEXT:
                const { varsData, variableItem } = reactAutoCompleteSettings(variables);

                const ttsText = (
                    <FieldWrapper
                        key='tts-field'
                        customClass='text-area-field autocomplete'
                        label='Content'>
                        <ReactTextareaAutocomplete
                            key='tts-text'
                            onChange={(e) => this.evtHandlers.ttsText(e.target.value)}
                            value={text}
                            loadingComponent={() => <Spinner />}
                            movePopupAsYouType
                            minChar={0}
                            tabOrEnter={true}
                            trigger={{
                                '{': {
                                    dataProvider: (d) => {
                                        const filtered = varsData.filter((f) =>
                                            f.name.toLowerCase().startsWith(d)
                                        );
                                        return filtered;
                                    },
                                    component: variableItem,
                                    output: (value) => `{{${value.name}}}`
                                }
                            }}
                        />
                    </FieldWrapper>
                );

                const ttsConfig = (
                    <TextToSpeechOptions
                        key='tts-config'
                        config={text_to_speech}
                        onChange={this.evtHandlers.ttsConfig}
                        showCopyPropIds={showCopyPropIds}
                        compositionId={compositionId}
                        layerId={layerId}
                    />
                );
                inputs.push(ttsText, ttsConfig);
                break;
            case SOURCE_TYPES.INVENTORY:
                const versionOptions = getVariableVersions(variables, inventory_id);
                const versionSelect =
                    layerType === COMPOSITION_LAYER_TYPES.VIDEO &&
                    inventory_id &&
                    versionOptions.length > 1 ? (
                        <SelectField
                            label={copy.global.version}
                            tooltip={copy.overlayConfig.tooltipVersion}
                            key='inventory-version-field'
                            width='50%'
                            value={version}
                            options={versionOptions}
                            onChange={this.evtHandlers.invVersion}
                        />
                    ) : null;

                const offsetField =
                    layerType === COMPOSITION_LAYER_TYPES.VIDEO && inventory_id ? (
                        <NumberField
                            key='inventory-offset-field'
                            label={copy.overlayConfig.offset}
                            tooltip={copy.overlayConfig.tooltipOffset}
                            width='50%'
                            value={offset || 0}
                            onChange={this.evtHandlers.videoOffset}
                        />
                    ) : null;

                let ttsInputs = null;

                if (text_to_speech) {
                    ttsInputs = (
                        <TextToSpeechOptions
                            key='inventory-tts-config'
                            config={text_to_speech}
                            onChange={this.evtHandlers.ttsConfig}
                            showCopyPropIds={showCopyPropIds}
                            compositionId={compositionId}
                            layerId={layerId}
                        />
                    );
                }

                inputs = [
                    <SelectField
                        label={copy.global.variable}
                        tooltip={copy.overlayConfig.tooltipVariable}
                        key='inventory-id-field'
                        width='50%'
                        value={inventory_id}
                        options={getVariableOptions(
                            variables,
                            hasTTS ? COMPOSITION_LAYER_TYPES.TEXT : asset_type
                        )}
                        onChange={this.evtHandlers.invId}
                    />,
                    ttsInputs,
                    versionSelect,
                    offsetField
                ];
                break;

            case SOURCE_TYPES.ASSET:
                inputs = [
                    <AssetField
                        api={api}
                        storyId={storyId}
                        label={copy.global.asset}
                        tooltip={copy.overlayConfig.tooltipAsset}
                        key='asset-field'
                        width='100%'
                        onChange={this.evtHandlers.assetId}
                        onError={this.evtHandlers.assetError}
                        accepts={asset_type}
                        assetId={asset_id}
                    />,
                    customVarFields
                ];

                break;

            case SOURCE_TYPES.ASSET_TAGS:
                inputs = [
                    <TagFields
                        key='tag-fields'
                        tags={asset_tags}
                        variables={variables}
                        assetType={layerType}
                        onChange={this.evtHandlers.assetTags}
                    />,
                    customVarFields
                ];
                break;
        }

        return inputs;
    }

    private async downloadAssetMap() {
        const { storyId } = this.props;

        const map = await api.getAssetMap(storyId);
        const str = `window.IMPOSIUM_ASSETS=${JSON.stringify(map.assets)};`;
        const blob = new Blob([str], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'assets.js');
    }

    private async downloadTemplate() {
        const {
            story,
            project: { actId, compositionId },
            compositions,
            activeTimelineLayer,
            storyId
        } = this.props;

        const act = actId !== '' ? story.acts[actId] : getFirstAct(story);
        const variables = act.inventory;
        const config = compositions.present[compositionId];
        const fps = config.rate;
        const layer = config.layers.find((f) => f.id === activeTimelineLayer);
        const archiveName: string = `${story.name} - Template.zip`;
        const type = layer.options.animated ? TEMPLATE_TYPES.IMAGE_SEQUENCE : TEMPLATE_TYPES.IMAGE;
        const frame_count = layer.end_frame - layer.start_frame;
        const map = await api.getAssetMap(storyId);
        const templateOverrides: ITemplateArchiveBody = {
            fps,
            frame_count,
            frames: frame_count,
            frame: 1,
            type,
            assets: map.assets,
            width: parseFloat(String(config.width)),
            height: parseFloat(String(config.height)),
            background_color: layer.options.background_color,
            inventory: {}
        };

        const variablesKeys: string[] = Object.keys(variables);

        if (variablesKeys.length > 0) {
            variablesKeys.forEach((key) => {
                if (variables[key].hasOwnProperty('id')) {
                    templateOverrides.inventory[variables[key].id] = null;

                    if (
                        variables[key].hasOwnProperty('previewItem') &&
                        variables[key].previewItem.hasOwnProperty('src') &&
                        !variables[key].previewItem.hasOwnProperty('url')
                    ) {
                        templateOverrides.inventory[variables[key].id] =
                            variables[key].previewItem.src;
                    }

                    if (
                        variables[key].hasOwnProperty('previewItem') &&
                        variables[key].previewItem.hasOwnProperty('url')
                    ) {
                        templateOverrides.inventory[variables[key].id] =
                            variables[key].previewItem.url;
                    }
                }
            });
        }

        downloadTemplateArchive(archiveName, templateOverrides);
    }

    public render() {
        const {
            source: { from, optional },
            typeOptions,
            layerType
        } = this.props;

        const wrapperStyle = {
            display: 'flex',
            justifyContent: 'space-between'
        };

        const optionalField = from === SOURCE_TYPES.ASSET_TAGS && (
            <CheckboxField
                label={copy.layerConfig.optional}
                width='50%'
                onChange={this.evtHandlers.optional}
                value={optional}
            />
        );

        const templateVersion = packageJson.dependencies['imposium-template-engine'].replace(
            /\^/g,
            'v'
        );

        return (
            <>
                <div style={wrapperStyle}>
                    {layerType !== COMPOSITION_LAYER_TYPES.SOLID ? (
                        <>
                            <SelectField
                                label={copy.layerConfig.sourceType}
                                tooltip={copy.layerConfig.tooltipSourceType}
                                value={from}
                                width='50%'
                                options={typeOptions}
                                onChange={this.evtHandlers.sourceType}
                            />
                            {optionalField}
                        </>
                    ) : null}
                    {layerType === COMPOSITION_LAYER_TYPES.TEMPLATE ? (
                        <div
                            style={{
                                marginRight: 'auto',
                                position: 'relative',
                                bottom: '2px',
                                display: 'flex'
                            }}>
                            <Button onClick={() => void this.downloadAssetMap()}>
                                {layerOptions.downloadAssetsJS}
                            </Button>
                            <Button onClick={() => void this.downloadTemplate()}>
                                {layerOptions.downloadSample}
                            </Button>
                            <div style={{ paddingLeft: '2px', paddingTop: '2px' }}>
                                {templateVersion}
                            </div>
                            <a
                                style={{ paddingLeft: '5px', paddingTop: '2px' }}
                                href='https://docs.imposium.com/template-engine/#/'
                                target='_blank'>
                                <Button
                                    style='subtle'
                                    size='small'
                                    color='default'>
                                    {ICON_QUESTION}
                                </Button>
                            </a>
                        </div>
                    ) : null}
                    {layerType === COMPOSITION_LAYER_TYPES.HTML ? (
                        <Button
                            customStyles={{
                                marginRight: 'auto',
                                position: 'relative',
                                bottom: '2px'
                            }}
                            onClick={() => this.props.createNewHTMLAsset()}>
                            {layerOptions.createHTML}
                        </Button>
                    ) : null}
                    {this.renderAssetsAvailable()}
                </div>
                {this.renderSourceInputs()}
            </>
        );
    }
}

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        project: state.project,
        compositions: state.compositions,
        editor: state.editor,
        activeTimelineLayer: state.timeline[state.project.compositionId].activeTimelineLayer,
        layerErrors: state.layerErrors,
        layerSources: state.layerSources
    };
};

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            addViewer,
            createNewHTMLAsset,
            setLayerErrors,
            updateEditorConfig
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaSource);
